const stories = [
  {
    "id": 1,
    "title": "Rouge Passion",
    "text": "Lors d'une soirée mondaine, Élise repère un bel homme et joue un jeu de séduction, aimant laisser brûler le désir...",
    "tags": ["soirée"],
    "category": "Exhib",
    "date": "2024-05-30",
    "author": "Lola",
    "image": "https://images.unsplash.com/photo-1625856198195-909122522ec6?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGVyb3RpYyUyMGRhcmt8ZW58MHx8MHx8fDA%3D",
    "popularity": "5",
    fulltext: `Lors d'une soirée mondaine, Élise, une femme dans la quarantaine, se distingue par son allure élégante et son charisme indéniable. Sa robe rouge écarlate, moulante et audacieuse, attire tous les regards, mais c'est elle qui décide qui peut la contempler.

La soirée bat son plein, les invités discutent et rient, mais Élise a un objectif précis ce soir. Elle repère un homme au bar, un bel apollon à la silhouette sculpturale et au regard perçant. Son costume noir impeccablement taillé met en valeur sa carrure athlétique. Élise sent l'excitation monter en elle, décidée à jouer son jeu de séduction.

Elle s'approche lentement, ses talons claquant sur le sol en marbre. Le bruit attire l'attention de l'homme qui tourne la tête et la regarde, visiblement impressionné. Élise s'arrête à quelques pas de lui, un sourire mystérieux sur les lèvres.

"Bonsoir," dit-elle d'une voix douce et envoûtante. "Je m'appelle Élise."

"Bonsoir, Élise," répond-il, son regard captivé par ses yeux étincelants. "Je suis Marc."

"Enchantée, Marc," murmure-t-elle en lui tendant la main. Leurs doigts se touchent, et une étincelle semble passer entre eux. "Alors, que faites-vous ici, seul au bar ?"

"Juste prendre un verre," répond-il, un sourire charmeur aux lèvres. "Et vous ?"

"Je profite de la soirée," répond Élise en le regardant intensément. "Mais je dois avouer que votre présence rend les choses beaucoup plus intéressantes."

Elle se rapproche de lui, son corps frôlant le sien. Elle sent son souffle se couper légèrement, signe qu'il est déjà sous son emprise. Élise aime cette sensation de pouvoir, de domination. Elle adore jouer avec ses proies, les mener là où elle le souhaite.

"Voulez-vous danser ?" demande-t-elle en le fixant dans les yeux, un éclat de défi dans son regard.

"Je ne peux pas refuser une telle offre," répond-il, acceptant sa main. Ils se dirigent vers la piste de danse, et Élise prend les devants. Elle guide leurs mouvements, ses hanches ondulant sensuellement contre les siennes.

Au fil de la danse, Élise se rapproche encore plus, son corps épousant parfaitement celui de Marc. Elle sent son désir monter, palpable dans chaque geste, chaque regard. Elle aime cette sensation, cette montée d'adrénaline qui l'envahit.

"Vous dansez merveilleusement bien," murmure-t-il à son oreille, sa voix chargée de désir.

"Merci," répond-elle en souriant. "Mais ce n'est qu'un aperçu de ce que je peux faire."

Elle décide de pousser le jeu un peu plus loin. Ses mains glissent sur son torse, ses doigts effleurant légèrement son abdomen avant de remonter vers ses épaules. Elle voit l'effet que cela a sur lui, ses yeux brûlant de désir.

"Vous aimez ça, n'est-ce pas ?" murmure-t-elle, sa voix devenant plus provocante.

"Oui," répond-il, sa voix rauque de désir. "Beaucoup."

Élise sourit, satisfaite. Elle sait qu'elle le tient, qu'il est prêt à tout pour elle. Mais elle n'a pas l'intention de tout lui donner. Pas ce soir. Elle aime laisser un peu de mystère, un peu de désir inassouvi. Cela rend le jeu encore plus excitant.

La musique s'arrête, et elle recule légèrement, son regard toujours planté dans le sien. "Merci pour cette danse, Marc," dit-elle en s'éloignant lentement.

"Déjà ?" demande-t-il, visiblement déçu.

"Oui," répond-elle avec un sourire énigmatique. "Mais peut-être qu'un jour, nous pourrons continuer."

Elle lui lance un dernier regard intense avant de s'éloigner, ses hanches se balançant sensuellement. Marc la regarde partir, son esprit rempli de désir et de fantasmes. Élise sait qu'elle a réussi son coup. Elle aime dominer, et ce soir, elle a trouvé une nouvelle proie à ajouter à sa collection.`
  },
  {
    "id": 2,
    "title": "Désirs de Soie",
    "text": "Dans l'intimité de sa chambre, Sophie explore ses désirs profonds et redécouvre la beauté de s'aimer soi-même...",
    "tags": ["masturbation"],
    "category": "Douce",
    "date": "2024-06-02",
    "author": "AuNomDeLaRose",
    "image": "https://images.unsplash.com/photo-1619043518800-7f14be467dca?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "popularity": "6",
    fulltext: `Sophie, une femme dans la trentaine, mène une vie bien remplie. Entre son travail exigeant et ses nombreuses responsabilités, elle trouve rarement du temps pour elle-même. Ce soir-là, après une longue journée, elle décide de s'accorder un moment de détente bien mérité.

Elle entre dans sa chambre, un sanctuaire de calme et de sérénité. Les murs sont peints dans des tons doux de lavande, et des bougies parfumées diffusent une odeur apaisante de jasmin. Elle allume une musique douce et sensuelle, créant une ambiance parfaite pour se relaxer. Sophie se dirige vers sa garde-robe et en sort une robe de chambre en soie. La matière douce glisse sur sa peau nue, lui procurant une sensation de confort et de luxe.

Sophie s'allonge sur son lit, ses pensées dérivant vers ses désirs et ses fantasmes. Elle se relève alors, décidant d'ajouter un élément supplémentaire à son plaisir. Elle place un grand miroir face à son lit, s'assurant qu'elle pourra voir chaque mouvement, chaque courbe de son corps.

Elle se regarde dans le miroir, admirant la façon dont la soie glisse sur sa peau. "Je suis belle," murmure-t-elle, un sourire satisfait aux lèvres. Elle commence à explorer son corps, ses mains caressant doucement sa peau. La soie de sa robe ajoute une dimension supplémentaire à ses sensations, chaque mouvement amplifié par la douceur du tissu.

Elle hésite un instant, se demandant si elle devrait sortir son livre érotique préféré et son jouet. Mais en se regardant dans le miroir, elle se dit : "Je me suffirai à moi-même ce soir." Elle pose ses mains sur ses seins, sentant la fermeté et la sensibilité de ses tétons. "Je mérite ce moment," dit-elle en se regardant dans le miroir, ses doigts effleurant son cou et descendant lentement vers sa poitrine.

Elle écarte légèrement sa robe, révélant ses seins fermes et sensibles. Ses doigts effleurent ses tétons, les caressant doucement avant de les pincer délicatement. "Oh, c'est si bon," soupire-t-elle, ses yeux fixés sur son propre reflet.

Sophie continue d'explorer son corps, ses mouvements devenant de plus en plus intimes. Elle laisse sa main descendre lentement, suivant les courbes de son ventre jusqu'à atteindre l'endroit le plus sensible de son être. Ses doigts jouent avec son clitoris, alternant entre caresses légères et pressions plus fermes. "Oui, c'est exactement ce dont j'ai besoin," murmure-t-elle, sentant son excitation grandir.

Sa main gauche remonte vers ses seins, caressant et massant avec une douce intensité, tandis que sa main droite continue ses mouvements délicats mais précis sur son clitoris. "C'est tellement bon... ne t'arrête pas," se dit-elle, encouragée par les sensations qui envahissent son corps.

Elle se regarde fixement dans le miroir, son regard empli de désir pour elle-même. "Je m'aime... je suis belle," murmure-t-elle, ses mouvements devenant plus rapides et plus insistants à mesure que son excitation atteint de nouveaux sommets. Elle peut sentir la chaleur monter en elle, son corps entier vibrant de désir.

"Oui... encore... encore..." halète-t-elle, son corps se tendant alors qu'elle atteint l'apogée de son plaisir. Ses doigts continuent de bouger, amplifiant les ondes de son orgasme. Finalement, elle se relâche dans un soupir de satisfaction, son corps entier frémissant.

Elle reste allongée, savourant les dernières vagues de plaisir qui parcourent son corps. La soie de sa robe, désormais froissée, témoigne de l'intensité de son moment de plaisir. Sophie sourit, se sentant complètement détendue et en paix. "Je devrais faire ça plus souvent," murmure-t-elle, satisfaite. Elle se contemple, ses joues toutes rouges, et s'admire dans le miroir, heureuse de s'être reconnectée avec elle-même et d'avoir redécouvert ses désirs de soie.`,
  },
  {
    "id": 3,
    "title": "Les Reflets du Désir",
    "text": "Dans l'intimité d'une piscine municipale, les reflets de l'eau révèlent un désir caché...",
    "fulltext": `Un jeune homme, Louis, 22 ans, se trouve à la piscine municipale un jour d'été chaud. Il y vient souvent pour se rafraîchir et échapper à la routine quotidienne. Après s'être changé rapidement dans une cabine, il se précipite vers les bassins.

Il plonge dans l'eau fraîche, sentant immédiatement la tension de sa journée se dissiper. Louis commence à faire des longueurs, ses mouvements réguliers et puissants créant une cadence apaisante. L'eau glisse sur sa peau, fraîche et vivifiante, tandis que les éclats de lumière dansent à la surface. Il alterne les styles, passant de la brasse au crawl, savourant la sensation de liberté que lui procure chaque mouvement. En nageant, il ne peut s'empêcher de remarquer les jeunes femmes autour de lui, riant et bavardant. Il se demande pourquoi il est encore célibataire. Peut-être n'a-t-il pas encore trouvé la bonne personne ou peut-être qu'il ne se donne pas suffisamment de chance.

Après une bonne demi-heure de nage, il se sent revigoré et satisfait de sa séance. Content de son entraînement, il sort de l'eau, sentant l'air tiède sur sa peau humide. Il marche tranquillement vers les cabines, savourant la sensation de ses muscles dessinés et détendus. Une fois arrivé, il se dirige vers une cabine pour se changer.

Alors qu'il se déshabille, il remarque un mouvant sur le sol du coin de l'oeil. Intrigué, il observe plus attentivement et remarque que les flaques d'eau par terre créent un reflet inattendu...

Une femme se déshabille avec élégance, elle ne semble pas se douter qu'un intru l'observe, préparant son maillot de bain. Ses mouvements sont lents et gracieux, chaque geste semblant chorégraphié. Louis lui donne la quarantaine, une beauté mature et affirmée.

Il ne peut se détacher du spectacle. Elle a une grâce et une beauté qui le fascinent et l'attirent irrésistiblement. Elle a des courbes délicates et une peau légèrement hâlée par le soleil. Ses cheveux sont longs et ondulent doucement sur ses épaules, captant la lumière de la pièce.

Elle enlève son haut, révélant une poitrine parfaite, délicatement galbée, des seins fermes qui ne le laisse pas indifférent. Ses jambes sont longues et bien formées, se terminant par des pieds délicats. Elle porte de la lingerie noire en dentelle, contrastant magnifiquement avec sa peau dorée.

Les couleurs dans la cabine semblent s'intensifier sous son regard. Le bleu pâle des carreaux de faïence contraste avec la chaleur dorée de sa peau. Les ombres jouent sur son corps, accentuant ses courbes et ajoutant une profondeur presque irréelle à la scène. Louis sent son cœur battre plus fort, chaque pulsation résonnant dans ses oreilles.

En fermant les yeux il peut presque sentir la chaleur de sa peau, l'odeur légère de chlore se mêlant à une douce fragrance de vanille. L'air semble s'électrifier autour de lui, chaque sens en alerte maximale.

Soudain un bruit, il ouvre les yeux... et la belle s'est volatilisée. Louis reste là, stupéfait, se demandant s'il a rêvé ou si elle a été réelle. Il se rhabille rapidement, son esprit tourbillonnant de questions et de désirs inassouvis.

La suite ... à suivre !
`,
    "tags": ["piscine"],
    "category": "Voyeurisme",
    "date": "2024-06-01",
    "author": "Caroline",
    "image": "https://images.unsplash.com/photo-1562016600-ece13e8ba570?q=80&w=1938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "popularity": "1"
  }/*,
    {
        "id": 4,
        "title": "Le Château des Ombres",
        "text": "Au sommet d'une colline, un château mystérieux est hanté par des esprits du passé...",
        "tags": ["horreur", "fantôme", "mystère"],
        "category": "Horreur",
        "date": "2024-08-05",
        "author": "Pierre Martin",
        "image": "https://images.unsplash.com/photo-1519817006514-8b677cd06028?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8c2V4JTIwZGFya3xlbnwwfHwwfHx8MA%3D%3D"
    },
    {
        "id": 5,
        "title": "Les Gardiens du Temps",
        "text": "Des héros sont choisis pour protéger le flux du temps et empêcher les anomalies temporelles...",
        "tags": ["science-fiction", "aventure", "temps"],
        "category": "Science-Fiction",
        "date": "2024-09-01",
        "author": "Sophie Laurent",
        "image": "https://images.unsplash.com/photo-1637623455310-a3862234de6f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8c2V4JTIwZGFya3xlbnwwfHwwfHx8MA%3D%3D"
    },
    {
        "id": 6,
        "title": "Les Gardiens du Temps",
        "text": "Des héros sont choisis pour protéger le flux du temps et empêcher les anomalies temporelles...",
        "tags": ["science-fiction", "aventure", "temps"],
        "category": "Science-Fiction",
        "date": "2024-09-01",
        "author": "Sophie Laurent",
        "image": "https://images.unsplash.com/photo-1635450604741-609961fdc009?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE4fHx8ZW58MHx8fHx8"
    },
    {
        "id": 7,
        "title": "Les Gardiens du Temps",
        "text": "Des héros sont choisis pour protéger le flux du temps et empêcher les anomalies temporelles...",
        "tags": ["science-fiction", "aventure", "temps"],
        "category": "Science-Fiction",
        "date": "2024-09-01",
        "author": "Sophie Laurent",
        "image": "https://images.unsplash.com/photo-1590364962261-20baeb6262a7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDR8fHxlbnwwfHx8fHw%3D"
    }*/
]

export default stories;