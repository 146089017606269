// pages/TermsOfService.js
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  background-color: #16213e;
  color: #eaeaea;
  max-width: 800px;
  margin: 20px auto;
  margin-top: 150px;
  margin-bottom: 150px;
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
`;

const Title = styled.h1`
  font-size: 2em;
  margin-bottom: 20px;
`;

const Section = styled.section`
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-size: 1.5em;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  font-size: 1em;
  margin-bottom: 10px;
`;

const TermsOfService = () => {
    return (
        <Container>
            <Title>Conditions Générales d'Utilisation</Title>
            <Section>
                <Subtitle>1. Introduction</Subtitle>
                <Paragraph>
                    Bienvenue sur notre site. En accédant à notre site, vous acceptez ces Conditions Générales d'Utilisation (CGU).
                </Paragraph>
            </Section>
            <Section>
                <Subtitle>2. Utilisation du Site</Subtitle>
                <Paragraph>
                    Vous vous engagez à utiliser notre site de manière légale et respectueuse. Toute utilisation abusive ou illégale est interdite.
                </Paragraph>
            </Section>
            <Section>
                <Subtitle>3. Protection des Données</Subtitle>
                <Paragraph>
                    Nous respectons votre vie privée. Veuillez consulter notre Politique de Confidentialité pour plus de détails sur la manière dont nous gérons vos données personnelles.
                </Paragraph>
            </Section>
            <Section>
                <Subtitle>4. Contenu du Site</Subtitle>
                <Paragraph>
                    Le contenu de notre site est protégé par les lois sur la propriété intellectuelle. Vous ne pouvez pas reproduire ou distribuer ce contenu sans notre autorisation.
                </Paragraph>
            </Section>
            <Section>
                <Subtitle>5. Modifications des CGU</Subtitle>
                <Paragraph>
                    Nous nous réservons le droit de modifier ces CGU à tout moment. Les modifications prendront effet dès leur publication sur le site.
                </Paragraph>
            </Section>
            <Section>
                <Subtitle>6. Contact</Subtitle>
                <Paragraph>
                    Si vous avez des questions concernant ces CGU, veuillez nous contacter à l'adresse suivante : contact@example.com.
                </Paragraph>
            </Section>
        </Container>
    );
};

export default TermsOfService;
