// pages/StoryPage.js
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const Main = styled.main`
  padding: 20px;
  background-color: #f0f0f0;
  min-height: calc(100vh - 140px); // Adjust to account for header and footer
`;

const StoryPage = () => {
    const { id } = useParams();
    // Fetch story by ID
    const story = { id, title: 'Sample Story', text: 'This is the story text.' };

    return (
        <Main>
            <h1>{story.title}</h1>
            <p>{story.text}</p>
        </Main>
    );
};

export default StoryPage;
