// pages/HomePage.js
import React from 'react';
import StoryList from '../components/StoryList';
import styled from 'styled-components';

const Main = styled.main`
  background-color: #06000ba6;
  padding: 70px 20px;
  width: 100%;
  margin:0px;
`;

const HomePage = () => {
    return (
        <Main>
            <StoryList />
        </Main>
    );
};

export default HomePage;
