// components/StoryCard.js
import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const tagColors = {
    masturbation: '#ff8c94',
    test: '#f6cd61',
    magie: '#88d8b0',
    piscine: '#8c82ff',
    histoire: '#ffab76',
    thriller: '#ff6f61',
    trésor: '#f0e68c',
    soirée: '#6a0572',
    fantôme: '#c5b0d5',
    "science-fiction": '#33b5e5',
    temps: '#ffcc00'
};

const Card = styled.div`
  background-color: #00000052;
  backdrop-filter: blur(10px);
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  min-width: 300px;

  &:hover {
    background-color: #00000000;
    transform: translateY(-5px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.7);
  }
`;

const CardTitle = styled.h2`
  font-size: 1.5em;
  margin: 0 0 10px;
  color: #eaeaea;
`;

const CardText = styled.p`
  font-size: 1em;
  color: #ccc;
  margin: 0 0 20px;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const Tag = styled.span`
  background-color: ${({ color }) => color || '#fff'};
  color: #fff;
  border-radius: 20px;
  padding: 5px 15px;
  margin: 5px 5px 0 0;
  font-size: 0.9em;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const Category = styled.div`
  margin-top: 10px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #f0f0f0;
  }
`;

const MetaInfo = styled.div`
  font-size: 0.8em;
  color: #aaa;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
`;

const MetaLink = styled.span`
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #f0f0f0;
  }
`;
const ImageContainer = styled.div`
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 15px;
  margin-bottom: 15px;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StoryCard = ({ id, title, text, tags, category, date, author, image }) => {
    const navigate = useNavigate();

    const handleStoryClick = () => {
        navigate(`/histoire/${id}/${title}`);
    };

    const handleTagClick = (e, tag) => {
        e.stopPropagation();
        navigate(`/tags/${tag}`);
    };

    const handleCategoryClick = (e) => {
        e.stopPropagation();
        navigate(`/category/${category}`);
    };

    const handleAuthorClick = (e) => {
        e.stopPropagation();
        navigate(`/author/${author}`);
    };

    return (
        <Card onClick={handleStoryClick}>
            <ImageContainer>
                <Image src={image} alt={title} />
            </ImageContainer>
            <CardTitle>{title}</CardTitle>
            <CardText>{text}</CardText>
            <Category /*onClick={handleCategoryClick}*/>{category}</Category>
            <Tags>
                {tags.map(tag => (
                    <Tag
                        key={tag}
                        color={tagColors[tag.toLowerCase()]}
                        /*onClick={(e) => handleTagClick(e, tag)}*/
                    >
                        {tag}
                    </Tag>
                ))}
            </Tags>
            <MetaInfo>
                <span>{date}</span>
                <MetaLink /*onClick={handleAuthorClick}*/>{author}</MetaLink>
            </MetaInfo>
        </Card>
    );
};

export default StoryCard;
