// pages/StoryPage.js
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import stories from '../stories.js'

const Main = styled.main`
  padding: 20px;
  margin-top: 120px;
  background-color: #f0f0f022;
  
    backdrop-filter: blur(5px);
  min-height: calc(100vh - 140px); // Adjust to account for header and footer
`;

const Title = styled.h1`
  font-size: 2em;
  margin-bottom: 20px;
`;

const MetaInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
  margin-bottom: 20px;
`;

const Author = styled.p`
  font-size: 1em;
  margin-bottom: 10px;
`;

const Date = styled.p`
  font-size: 0.9em;
  color: #999;
  margin-bottom: 20px;
`;
const FullText = styled.div`
  white-space: pre-line; /* Handle new lines correctly */
  line-height: 1.6;
  text-align: justify;
`;
const StoryPage = () => {
    const { id } = useParams();
    // Fetch story by ID

    const story = stories.find(story => story.id === parseInt(id));

    return (
        <Main>
            <Title>{story.title}</Title>
            <MetaInfo>
                <span>Par {story.author}</span>
                <span>Le {story.date}</span>
            </MetaInfo>
            <FullText>{story.fulltext}</FullText>
        </Main>
    );
};

export default StoryPage;
