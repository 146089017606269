// components/StoryList.js
import React, { useState } from 'react';
import StoryCard from './StoryCard';
import styled from 'styled-components';
import stories from '../stories.js'



const Section = styled.section`
  margin: 50px 0;
`;

const SectionTitle = styled.h2`
  font-size: 1.8em;
  color: #fff;
  margin-bottom: 10px;
`;

const HorizontalScroll = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 10px;
  padding: 10px 0;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #6a0dad;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #1a1a2e;
  }
`;

const categorizeStories = (stories) => {
    const categories = {};
    stories.forEach(story => {
        if (!categories[story.category]) {
            categories[story.category] = [];
        }
        categories[story.category].push(story);
    });
    return categories;
};

const categorizedStories = categorizeStories(stories);

const StoryList = () => {
    return (
        <div>
            <Section>
                <SectionTitle>Les Plus Récentes</SectionTitle>
                <HorizontalScroll>
                    {stories.sort((a, b) => new Date(b.date) - new Date(a.date)).
                    slice(0, 7).map(story => (
                        <StoryCard key={story.id} {...story} />
                    ))}
                </HorizontalScroll>
            </Section>

            <Section>
                <SectionTitle>Les Plus Populaires</SectionTitle>
                <HorizontalScroll>
                    {stories.sort((a, b) => b.popularity - a.popularity).slice(0, 5).map(story => (
                        <StoryCard key={story.id} {...story} />
                    ))}
                </HorizontalScroll>
            </Section>

            {/*<Section>
                <SectionTitle>Celles Qui Pourraient Vous Plaire</SectionTitle>
                <HorizontalScroll>
                    {stories.slice(0, 5).map(story => (
                        <StoryCard key={story.id} {...story} />
                    ))}
                </HorizontalScroll>
            </Section>*/}

            {/*{Object.keys(categorizedStories).map(category => (
                <Section key={category}>
                    <SectionTitle>{category}</SectionTitle>
                    <HorizontalScroll>
                        {categorizedStories[category].map(story => (
                            <StoryCard key={story.id} {...story} />
                        ))}
                    </HorizontalScroll>
                </Section>
            ))}*/}
            <SectionTitle>Histoires à suivre...</SectionTitle>
        </div>
    );
};

export default StoryList;