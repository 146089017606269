// components/CookieConsentModal.js
import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  text-align: center;
  color: #333333;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: #000;
`;

const Message = styled.p`
  margin-bottom: 20px;
`;

const Button = styled.button`
  background: #4b0016;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 10px;
  font-size: 1em;

  &:hover {
    background: #6a0dad;
  }
`;

const CookieConsentModal = ({ onAccept, onReject }) => (
    <ModalOverlay>
        <ModalContent>
            <Title>Vérification de l'âge</Title>
            <Message>
                Ce site est destiné à un public adulte. Veuillez confirmer que vous avez au moins 18 ans pour continuer.
            </Message>
            <Title>Autorisation des Cookies</Title>
            <Message>
                Ce site utilise des cookies pour vous garantir la meilleure expérience sur notre site. En continuant à naviguer sur ce site, vous acceptez notre utilisation des cookies.
            </Message>
            <div>
                <Button onClick={onReject}>Refuser</Button>
                <Button onClick={onAccept} style={{backgroundColor:'green'}}>Confirmer & Accepter</Button>
            </div>
        </ModalContent>
    </ModalOverlay>
);

export default CookieConsentModal;
