// App.js
import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import GlobalStyle from './GlobalStyle';
import CookieConsentModal from './components/CookieConsentModal';
import HomePage from './pages/HomePage';
import StoryPage from './pages/StoryPage';
import AuthorPage from './pages/AuthorPage';
import CategoryPage from './pages/CategoryPage';
import TagPage from './pages/TagPage';
import Header from './components/Header';
import Footer from "./components/Footer";
import styled from 'styled-components';
import TermsOfService from "./pages/TermsOfService";

const AppContainer = styled.div`
  filter: ${({blur}) => (blur ? 'blur(5px)' : 'none')};
  transition: filter 0.3s ease;
`;
const App = () => {
    const [showCookieModal, setShowCookieModal] = useState(false);

    useEffect(() => {
        const cookieConsent = localStorage.getItem('cookieConsent');
        if (!cookieConsent) {
            setShowCookieModal(true);
        }else{
            if(cookieConsent === 'accepted'){
                const script = document.createElement('script');
                script.src = 'https://www.googletagmanager.com/gtag/js?id=G-S1XQFJV9XQ';
                script.async = true;
                document.head.appendChild(script);

                const dataLayerScript = document.createElement('script');
                dataLayerScript.innerHTML = `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-S1XQFJV9XQ');`;
                document.head.appendChild(dataLayerScript);
            }
        }
    }, []);

    const handleAcceptCookies = () => {
        localStorage.setItem('cookieConsent', 'accepted');
        setShowCookieModal(false);
    };



    const handleRejectCookies = () => {
        localStorage.setItem('cookieConsent', 'rejected');
        setShowCookieModal(false);
        window.location.href = 'https://www.google.com';
    };
    return (
        <Router>
            <GlobalStyle/>
            <AppContainer blur={showCookieModal}>
                <Header/>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/histoire/:id/:name" element={<StoryPage/>}/>
                    <Route path="/author/:author" element={<AuthorPage/>}/>
                    <Route path="/category/:category" element={<CategoryPage/>}/>
                    <Route path="/tags/:tag" element={<TagPage/>}/>
                    <Route path="/terms" element={<TermsOfService />} />
                </Routes>
            </AppContainer>
                {showCookieModal && (
                    <CookieConsentModal
                        onAccept={handleAcceptCookies}
                        onReject={handleRejectCookies}
                    />
                )}
                <Footer/>
        </Router>
);
};

export default App;
