// components/Header.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

const HeaderContainer = styled.header`
  background-color: #0f346000;
  color: #eaeaea;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  position: fixed;
  z-index: 1000;
  width: 100%;
  background: transparent;
  box-shadow: 0 0 1px 3px #00000038;
  backdrop-filter: blur(10px);
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  width: 150px;
`;

const LogoText = styled.h1`
  font-size: 1.8em;
  margin: 0;
  margin-left: 10px; /* Space between image and text */
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    background-color: #58051f;
    position: absolute;
    top: 70px;
    left: ${({ open }) => (open ? '0' : '-100%')};
    width: 100%;
    transition: left 0.3s ease-in-out;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  }

  a {
    color: #eaeaea;
    margin: 0 10px;
    text-decoration: none;
    font-size: 1.1em;

    &:hover {
      text-decoration: underline;
    }

    @media (max-width: 768px) {
      margin: 10px 0;
    }
  }
`;

const Hamburger = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Header = () => {
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <HeaderContainer>
            <Link to="/">
                <LogoContainer>
                    <LogoImage src="/logo.png" alt="Rougir" />
                </LogoContainer>
            </Link>
            <Nav open={open}>
                <Link to="/" onClick={handleToggle}>Accueil</Link>
                {/* <Link to="/newStory">Ajouter</Link> */}
            </Nav>
            <Hamburger onClick={handleToggle}>
                {open ? <FaTimes size={24} /> : <FaBars size={24} />}
            </Hamburger>
        </HeaderContainer>
    );
};

export default Header;
