// components/Footer.js
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterContainer = styled.footer`
  background-color: #00000040;
  backdrop-filter: blur(10px);
  color: #00000087;
  text-align: center;
  padding: 10px;
  /*position: fixed;*/
  width: 100%;
  bottom: 0;
`;

const FooterLink = styled(Link)`
  color: #6a0dad;
  text-decoration: none;
  font-size: 1em;

  &:hover {
    text-decoration: underline;
  }
`;
const Footer = () => {
    return (
        <FooterContainer>
            &copy; 2024 Rougir - Tous droits réservés - <FooterLink to="/terms">CGU</FooterLink>
        </FooterContainer>
    );
};

export default Footer;